<template>
  <div id="signin" class="signin-page color-bg-4">
        <div class="singin-banner">
          <img src="../../assets/logo-cima-foo.png" alt="Logo Cimarrones FC" >
        </div>
        <div class="form-container ">
           <div class="redes-pc">
             <div class="signin_redes ">
                <a href="#"><img src="../../assets/fb-log.png" alt="fb" class="signin-logo"></a>
                <a href="#"><img src="../../assets/ins-log.png" alt="ins" class="signin-logo"></a>
                <a href="#"><img src="../../assets/in-log.png" alt="in" class="signin-logo"></a>
                <a href="#"><img src="../../assets/tw-log.png" alt="tw" class="signin-logo"></a>
             </div>
           </div>
            <div class="form-signin">
              <h4 class="">REGISTRATE</h4>
              <form
                @submit.prevent="
                  submit(
                    name_form,
                    lastname_form,
                    email_form,
                    password_form,
                    passconfirm_form,
                    phone_form
                  )
                "
              >
                <div class="dflx grp1">
                  <div class="grp ">
                    <label class="sgnlabelN " for="sgninputN">Nombre</label>
                    <b-form-input
                    
                      v-model="name_form"
                      required
                      pattern="^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$"
                      id="sgninputN"
                      type="text"
                    />
                  </div>
                  <div class="grp ">
                    <label class="sgnlabelA " for="sgninputA"
                      >Apellido</label
                    >
                    <b-form-input
                      v-model="lastname_form"
                      required
                      pattern="^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$"
                      id="sgninputA"
                      type="text"
                    />
                  </div>
                </div>
              
                <div class="dflx grp2">
                  <div class="grp">
                    <label class="sgnlabelE " for="sgninputE">Email</label>
                    <b-form-input   v-model="email_form" required id="sgninputE" type="email" />
                  </div>
                  <div class="grp">
                    <label class="sgnlabelTel " for="sgninputTel">Teléfono</label>
                    <b-form-input
                    
                      v-model="phone_form"
                      required
                      minlength="10"
                      maxlength="10"
                      size="10"
                      pattern="\d*"
                      id="sgninputTel"
                      type="text"
                    />
                  </div>
                 
                  
                </div>
               
              
                  <div class="dflx">
                   <div class="grp">
                    <label class="sgnlabelP " for="sgninputP"
                      >Contraseña</label
                    >
                    <b-form-input
                      v-model="password_form"
                      required
                      id="sgninputP"
                      minlength="8"
                      :class="{ invalid : msg == 'Las contraseñas no coinciden.'}"
                      type="password"
                      :placeholder="'***********'"
                    />
                  </div>
                   <div class="grp">
                    <label class="sgnlabelP " for="sgninputPC"
                      >Confirmar contraseña</label
                    >
                    <b-form-input
                      v-model="passconfirm_form"
                      required
                      id="sgninputPC"
                      minlength="8"
                      :class="{ invalid : msg == 'Las contraseñas no coinciden.'}"
                      type="password"
                       :placeholder="'***********'"
                    />
                  </div>
              
                  
                </div>
              
                <div class="">
                  <div class="div-error" v-if="this.status=='error'">
                      <p>{{this.msg}}</p>
                  </div>
              
                </div>
               
                <div class="btn-signin">
                  <button 
                    variant="outline-secondary"
                    class=""
                    type="submit"
                    >REGISTRATE  <img src="../../assets/Arrow_6.png" alt="imagen flecha"></button>
                  <b-modal
                    id="modal-registro"
                    ref="modal-registro"
                    no-close-on-backdrop
                    centered
                    hide-footer
                    hide-header
                  >
                    <div class="message-modal">
                      <img :src="IconSuccess" class="success" /><br />
                      <p class="text-modal">Registro exitoso</p>
                    </div>
                    <div class="">
                      <b-button class="btn-modal" @click="login()"
                        >Iniciar Sesión</b-button
                      >
                      <b-button
                        type="button"
                        aria-label="Close"
                        class="close-modal"
                        @click="home()"
                        >×</b-button
                      >
                    </div>
                  </b-modal>
                  
                </div>
              </form>

               <div class=" redes-mv">
                 <div class="signin_redes">
                    <a href="#"><img src="../../assets/fb-log.png" alt="fb" class="signin-logo"></a>
                    <a href="#"><img src="../../assets/ins-log.png" alt="ins" class="signin-logo"></a>
                    <a href="#"><img src="../../assets/in-log.png" alt="in" class="signin-logo"></a>
                    <a href="#"><img src="../../assets/tw-log.png" alt="tw" class="signin-logo"></a>
                  </div>

               </div>



            </div>
        </div>
  </div>
</template>
<script>

import IconSuccess from "../../assets/Vector.png";
export default {
  name: 'signin',
  data: () => ({
    name_form:"",
    lastname_form:"",
    email_form: "",
    password_form: "",
    passconfirm_form: "",
    phone_form:"",
    status:'',
    msg:'',
    IconSuccess: IconSuccess,
    
  }),
 
  methods: {
  wait: function () {
      setTimeout(() => this.$router.go(), 200);
  },
  home: function () {
      this.$router.push("/").catch(err => {});
  },
  login: function () {
      this.$router.push("/login").catch(err => {});
  },
  showModal(){
       this.$refs['modal-registro'].show()
    },
  submit: async function (name, lastname, email, password,passconfirm, phone) {
       
      if(password === passconfirm){
        let user = {
                name: name,
                lastname: lastname,
                email: email,
                password: password,
                role:  'user',
                phone: phone
              };
              
          this.status=''
          this.msg=''
          
          
          let result = await this.$store.dispatch("main/addItem",  {option:'user', item: user});
         
          if(result.status=='error'){
            this.status='error'
            this.msg= result.message
          }else{ // success
              this.showModal() 
              // PENDIENTE REDIRIGIR A ALGUNA PAGINA...
          }
         
      }else{
       
         this.status='error'
         this.msg='Las contraseñas no coinciden.'
      }
     
      
      
    },
  }
}
</script>

<style  scoped>
.redes-mv{
  display: none;
}
#signin{
  display: flex;
}
#signin .singin-banner{
  background-image: url('../../assets/Rectangle_364.png')  !important;
  background-size: 100% !important;
  background: no-repeat;
  width: 36.822916666666664VW;
  height: 53.333333333333336VW;
}

.singin-banner img{
  width: 6.770833333333333VW;
  height: 7.5VW;
  margin-top: 2.5VW;
  margin-left: 2.7083333333333335VW;
}

.signin_redes img:hover {
    filter: brightness(3.5);
}

.signin_redes {
    margin-top: 3.4375000000000004VW;
    margin-left: 49.6875VW;
    
}

.signin_redes img{
    margin-right: 2.109375VW;
    width: auto;
    height: 0.8854166666666666VW;
    transition: 0.5s;
}


.form-signin{
  margin-left: 9.739583333333334VW;
  margin-top: 4.635416666666667VW;
}

.form-signin h4{
  font-family: 'Rajdhani';
  font-size: 2.083333333333333VW;
  color: #032A4B;
  margin-bottom: 5.520833333333333VW;
  font-weight: 800;
}

.form-signin label{
  font-size: 0.78125VW;
  color: #B70D1D;
  font-family: 'Rajdhani';
  margin-bottom: 0.78125VW;
}

.form-signin input{
  font-size: 0.78125VW;
  font-family: 'Rajdhani';
  border-radius: 0vw;
  width: 20.833333333333336VW;
  height: 3.6291666666666673VW;
  border: 0.052083333333333336VW solid #032A4B;
}

.form-signin input[type=password]::placeholder{
  font-size: 0.78125VW;
}

.dflx{
  display: flex;
}
.grp{
  margin-right: 1.3541666666666667VW;
}.grp1{
  margin-bottom: 1.7874999999999999VW;
}.grp2{
  margin-bottom: 2.3604166666666666VW;
}

.btn-signin button{
  border-radius: 0vw;
  border: 0vw;
  background: #B70D1D;
  color: #FFFFFF;
  width: 20.833333333333336VW;
  height: 3.6458333333333335VW;
  margin-left: 22.1875VW;
  margin-top: 3.6104166666666666VW;
  font-size: 0.78125VW;
    font-family: 'Rajdhani';
    text-align: left;
    padding-left: 2.2916666666666665VW;
    font-weight: 700;
   
}

.btn-signin button img{
  width: 2.8125VW;
  margin-left: 7.760417VW;
}

.div-error p{
  width: 20.833333333333336VW;
  padding: .5vw;
  height: auto;
  margin-left: 22.1875VW;
  margin-top: 1.3104166666666666VW;
  background: #B70D1D;
  color: #FFFFFF;
  font-family: 'Rajdhani';
}


.text-modal{
  text-align: center;
  
}

@media (max-width: 900px){

  .form-container{
    padding-top: 0vw;
  }
  
  .form-signin{
    margin-left: 0vw;
    margin-top: 0vw;
  }

  .signin_redes{
    margin-left: 27.6875VW;
  }

  .form-signin input{
     
      border: 0.24154589371980675VW solid #032A4B;
    
    }

    .form-signin label{
      font-size: 1.2vw;
    }

    .signin_redes img{
    height: 1VW;
   }

}


@media (max-width: 720px) {
    
    #signin{
      display: grid;
    }

    #signin .singin-banner{
      background-image: url('../../assets/Rectangle_364m.png')  !important;
      width: 100vw;
      height: 52.89855072463768VW;
    }

    .singin-banner img{
      width: 20.77294685990338VW;
      height: 23.18840579710145VW;
      margin-top: 12.80193236714976VW;
      margin-left: 12.077294685990339VW;
    }

    .redes-pc{
      display: none;
    }

    .redes-mv{
      display: block;
    }

    #signin .form-container{
      width: 100vw;
      padding: 0vw;
    }

     #signin .form-signin{
      margin-left: 14.492753623188406VW;
      margin-top: 12.077294685990339VW;
    }

    .form-signin h4{
      font-size: 6.038647342995169VW;
    }

    .dflx{
      display: block;
    }

    .form-signin label{
      font-size: 3.140096618357488VW;
       margin-bottom: 2.898550724637681VW;
    }

    .form-signin input{
      width: 70.28985507246377VW;
      height: 14.492753623188406VW;
      font-size: 3.6231884057971016VW;
      border: 0.24154589371980675VW solid #032A4B;
      margin-bottom: 6.763285024154589VW;
      padding-left: 6.038647342995169VW;
    }

    .form-signin input[type=password]::placeholder{
        font-size: 3.6231884057971016VW;
      }

    .grp{
      margin-right: 0VW;
    }.grp1{
      margin-bottom: 0VW;
    }.grp2{
      margin-bottom: 0VW;
    }


    .btn-signin button{
    
      width: 70.28985507246377VW;
      height: 14.492753623188406VW;
      font-size: 0.78125VW;
      font-family: 'Rajdhani';
      text-align: left;
      padding-left:7.971014492753622VW;
      font-weight: 700;
      margin-left: 0vw;
      margin-top: 1.932367149758454VW;
      font-size: 3.140096618357488VW;
     }

     .btn-signin button img{
       width: 13.043478260869565VW;
       margin-left: 22.760417VW;
     }

    .signin_redes {
        margin-top: 14.596618357487923VW;
       margin-left: 12.468599VW;
      margin-bottom: 14.492753623188406VW;
    }

    .signin_redes img{
        margin-right: 10.628019323671497VW;
        width: auto;
        height: 4.1062801932367154VW;
    }


    .div-error p{
      width: 69.833333VW;
      margin-left: 0VW;
 
     }


}

</style>


<!--

-->

